var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      staticClass: "dropdown-user",
      attrs: {
        right: "",
        "toggle-class": "d-flex align-items-center dropdown-user-link"
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function() {
            return [
              _c("div", { staticClass: "d-sm-flex d-none user-nav" }, [
                _c("p", { staticClass: "user-name font-weight-bolder mb-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.userData.fullName || _vm.userData.username) +
                      " "
                  )
                ]),
                _c("span", { staticClass: "user-status" }, [
                  _vm._v(_vm._s(_vm.userData.role))
                ])
              ]),
              _c(
                "b-avatar",
                {
                  staticClass: "badge-minimal",
                  attrs: {
                    size: "40",
                    src: _vm.userData.avatar,
                    variant: "light-primary",
                    badge: "",
                    "badge-variant": "success"
                  }
                },
                [
                  !_vm.userData.fullName
                    ? _c("feather-icon", {
                        attrs: { icon: "UserIcon", size: "22" }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.$can("update", "users")
        ? _c(
            "b-dropdown-item",
            {
              attrs: { "link-class": "d-flex align-items-center" },
              on: { click: _vm.editProfile }
            },
            [
              _c("feather-icon", {
                staticClass: "mr-50",
                attrs: { size: "16", icon: "SettingsIcon" }
              }),
              _c("span", [_vm._v("Edit Profile")])
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-dropdown-item",
        {
          attrs: { "link-class": "d-flex align-items-center" },
          on: { click: _vm.openSettings }
        },
        [
          _c("feather-icon", {
            staticClass: "mr-50",
            attrs: { size: "16", icon: "SettingsIcon" }
          }),
          _c("span", [_vm._v("Settings")])
        ],
        1
      ),
      _c(
        "b-dropdown-item",
        {
          attrs: { "link-class": "d-flex align-items-center" },
          on: { click: _vm.logout }
        },
        [
          _c("feather-icon", {
            staticClass: "mr-50",
            attrs: { size: "16", icon: "LogOutIcon" }
          }),
          _c("span", [_vm._v("Logout")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }